<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        员工管理
        <el-button @click="add" class="btn" type="primary" size="mini" :disabled="roleId == 2 || roleId == 3 || roleId == 4">新增员工</el-button>
      </div>
      <div class="list-box">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="sex" label="性别">
            <template slot-scope="scope">
              <span v-if="scope.row.sex == 1">男</span>
              <span v-else>女</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="联系方式"></el-table-column>
          <el-table-column prop="departmentName" label="部门"></el-table-column>
          <el-table-column prop="name" label="岗位">
            <template slot-scope="scope">
              <div
                v-for="item in scope.row.positionInfoList"
                :key="item.positionId"
              >{{item.positionName}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="detail(scope.row)">详情</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {},
      dialogTitle: "",
      dialogVisible: false,
      tableData: [],
      position: [],
      positionFilter: [],
      departmentTreeData: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      radio: {},
      roleId: 0
    };
  },
  methods: {
    departmentChange(id) {
      this.form.positionIdList = "";

      function filterFun(item) {
        return item.departmentId == id;
      }
      this.positionFilter = this.position.filter(filterFun);
    },
    detail(row) {
      this.$router.push({ name: 'staffDetail', params: { row: row } })
    },
    edit(row) {
      this.$router.push({ name: 'staffEdit', params: row })

      this.form = {
        id: row.id,
        name: row.name,
        sex: row.sex,
        phone: row.phone,
        positionIdList: (function () {
          let ids = [];
          row.positionInfoList.forEach((item) => {
            ids.push(item.positionId);
          });
          return ids;
        })(),
        departmentId: row.departmentId,
      };

      this.positionFilter = this.position.filter((item) => {
        return item.departmentId == row.departmentId;
      });
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("staffDelete", {
          id: row.id,
        }).then((res) => {
          this.loadList();
        });
      });
    },
    ok() {
      if (this.dialogTitle == "新增") {
        this.$ajax.post("staffCreate", this.form).then((res) => {
          this.dialogVisible = false;
          this.loadList();
        });
      } else {
        this.$ajax.post("staffUpdate", this.form).then((res) => {
          this.dialogVisible = false;
          this.loadList();
        });
      }
    },
    add() {
      this.$router.push({ name: 'staffAdd' })
    },
    loadList() {
      this.loading = true
      this.$ajax.get("staffDueryAll").then((res) => {
        this.tableData = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    loadDepartmentList() {
      this.$ajax.get("departmentTree").then((res) => {
        this.departmentTreeData = res.data;
      });
    },
    loadPositionList() {
      this.$ajax.get("positionQueryAll").then((res) => {
        this.position = this.getTreeData(res.data);
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
              departmentId: item.departmentId,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
  },
  mounted() {
    this.loadDepartmentList();
    this.loadPositionList();
    this.loadList();
    this.roleId = sessionStorage.getItem('r')
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
</style>